<template>
  <div>
    客服分配医生
  </div>
</template>

<script>
export default {
  name: "DoctorService",
  data() {
    return{

    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

</style>
